@media only screen and (min-width: 1270px) {
  .ui.container {
    width: 1230px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ui.grid.container {
    width: calc( 1230px  +  2rem ) !important;
  }

  .ui.relaxed.grid.container {
    width: calc( 1230px  +  3rem ) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc( 1230px  +  5rem ) !important;
  }

}

.taminickpainting {
  background: no-repeat top/103% url('images/taminick-painting.jpg');
  min-height: 1000px;
  padding-top: 2.8em;
}

@media only screen and (max-width: 1000px) {
  .taminickpainting {
    background: no-repeat top/150% url('images/taminick-painting.jpg');
  }
}

@media only screen and (max-width: 800px) {
  .taminickpainting {
    background: no-repeat top/210% url('images/taminick-painting.jpg');
  }
}

@media only screen and (max-width: 600px) {
  .taminickpainting {
    background: no-repeat top/270% url('images/taminick-painting.jpg');
  }
}


.homepagecontainer {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1em;
  padding: 2em;
  margin-top: 1.2em;
}
